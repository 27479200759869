<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import useContextSlug from '~/composables/use-context-slug';

const { t } = useI18n();
const route = useRoute();
const { $firebaseLogout } = useNuxtApp();
const { isAccountDirect } = useAppMode();
const { accountContext } = useContextSlug();
const user = useUser();

const props = defineProps<{
    homeUrl?: string;
    hideLogin?: boolean;
    hideAreaSelect?: boolean;
}>();

const menuItems = [
    {
        label: 'Account',
        href: '/account',
    },
    {
        label: 'Bookings',
        href: '/account/bookings',
    },
    {
        label: 'Log out',
        onClick: onLogout,
    }
];

const userInitials = computed<string>(() => {
    return (fullName.value?.split(' ') || []).map(a => a[0]).join('').toUpperCase();
});
const fullName = computed<string>(() => {
    if (!user.value) { return 'Unknown User'; }

    return user.value.firstName && user.value.lastName ? `${user.value.firstName} ${user.value.lastName}` : user.value.email;
});

async function onLogout () {
    await $firebaseLogout();

    if (route.meta.private) {
        navigateTo('/');
    }
}
</script>

<template>
    <div class="w-full md:h-[85px] pt-8 md:pt-0 mb-5 md:mb-0 md:border-b">
        <div class="w-full h-full flex flex-row md:justify-between items-center max-w-[1200px] m-auto px-4 md:px-8">
            <div class="md:flex-1 flex">
                <NuxtLink :to="props.homeUrl || '/'">
                    <img src="/layout/images/logo-home.svg" class="w-[135px] hidden md:block" width="135" height="35">
                    <img src="/layout/images/logo-colour-circle.svg" class="w-[41px] md:hidden" width="41" height="41">
                </NuxtLink>
            </div>
            <div class="flex-1 flex items-center justify-start md:justify-center">
                <HomeAreaSelect v-if="!props.hideAreaSelect && !isAccountDirect" />
                <div v-if="!props.hideAreaSelect && isAccountDirect" class="md:w-[250px] text-lg text-primary text-center px-3 whitespace-nowrap overflow-hidden overflow-ellipsis">
                    {{ accountContext?.name }}
                </div>
            </div>
            <div class="md:flex-1 text-right">
                <template v-if="!hideLogin">
                    <NuxtLink v-if="!user" to="/login">
                        <span class="sr-only md:!not-sr-only">{{ t('labels.loginOrRegister') }}</span>
                        <span class="md:!hidden fa fa-user-circle text-[2.5rem]" />
                    </NuxtLink>

                    <Menu v-else as="div" class="relative inline-block text-left">
                        <div>
                            <MenuButton class="flex items-center max-w-xs">
                                <div class="flex items-center justify-center rounded-full bg-black text-white overflow-hidden w-10 h-10">
                                    {{ userInitials }}
                                </div>
                            </MenuButton>
                        </div>

                        <transition
                            enter-active-class="transition ease-out duration-100"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95"
                        >
                            <MenuItems class="absolute right-0 w-[250px] z-10 mt-2 origin-top rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <MenuItem
                                    v-for="item in menuItems"
                                    :key="item.label"
                                    :as="item.href ? 'a' : 'button'"
                                    :class="['hover:bg-gray-100 cursor-pointer ui-active:bg-gray-100 ui-active:text-gray-900 ui-not-active:text-gray-700 block w-full rounded text-center px-4 py-2 text-sm']"
                                    :href="item.href"
                                    @click="item.onClick"
                                >
                                    {{ item.label }}
                                </MenuItem>
                            </MenuItems>
                        </transition>
                    </Menu>
                </template>
            </div>
        </div>
    </div>
</template>
