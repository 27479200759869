<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { useAppDataStore } from '@/stores/appdata';
import type { IPublicDirectDataState } from '@/stores/appdata-public-direct';
const dataStore = useAppDataStore() as IPublicDirectDataState;

function onAreaClick (areaId: number, areaSlug: string) {
    if (dataStore.selectedArea?.slug !== areaSlug) {
        navigateTo(`/${areaSlug}/${areaId}`);
    }
}

</script>

<template>
    <Menu as="div" class="relative inline-block text-left">
        <div>
            <MenuButton class="inline-flex md:w-[250px] items-center justify-center gap-x-1.5 px-3 py-2 text-lg text-primary">
                <span class="whitespace-nowrap overflow-hidden overflow-ellipsis">{{ dataStore.selectedArea?.name || 'Select Area' }}</span>
                <span class="fa fa-chevron-down -mr-1 h-5 w-5 text-[#202831]" aria-hidden="true" />
            </MenuButton>
        </div>

        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <MenuItems class="absolute w-[250px] z-10 mt-2 origin-top rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="py-1">
                    <MenuItem v-for="area in dataStore.areas" :key="area.id" v-slot="{ active }">
                        <button
                            :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block w-full px-4 py-2 text-sm']"
                            @click="onAreaClick(area.id, area.slug)"
                        >
                            {{ area.name }}
                        </button>
                    </MenuItem>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>
